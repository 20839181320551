// src/utils/request.ts
   
import axios from "axios";
import qs from 'qs'

const request = axios.create({
  baseURL: 'https://console.haiwojiamei.com/api/wechat' // 正式环境
  // baseURL: "https://dev.console.haiwojiamei.com/api/wechat", // 测试环境
});
// config => {
//     return config;
// },
// error => {
//     console.log(error);
//     return Promise.reject();
// };
// request.interceptors.request.use((config) => {
// const token = localStorage.getItem('token')
// if (token) {
//     // 请求头携带token
//     config.headers.token = token
// }
// console.log('正在加载....')
// return config
// }, (error) => {
// return Promise.reject(error)
// })
// request.interceptors.response.use(
// response => {
//     if (response.status === 200) {
//         return response.data;
//     } else {
//         Promise.reject();
//     }
// },
// error => {
//     console.log(error);
//     return Promise.reject();
// });
// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // 统一设置用户身份 Token
    const token = localStorage.getItem("token");
    if (token) {
      // 请求头携带token
      config.headers["token"] = token;
      config.headers["Content-Type"] ='application/x-www-form-urlencoded'
      if (config.url !== '/user/upload') {
        config.data = qs.stringify(config.data) // 转为formdata数据格式
      }
    }
    // console.log(config, "正在加载....");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    // 统一处理响应错误，例如 token 无效、服务端异常等
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default request;
