<template>
  <div class="box">
  </div>
</template>

<script>
import { Base64 } from "../utils/base.js";
import { login } from "../api/index";
export default {
  data() {
    return {
      redirect_url: "https://binding.haiwojiamei.com/quick",
      isShow: false,
    };
  },
  created() {},
  mounted() {
    console.log(this.$route.query.code, this.$route.query.openid);
    var test = window.location.href;
    console.log("看看拿到的路由", test);
    let code = Number(this.$route.query.code);
    let openid = this.$route.query.openid
      ? this.$route.query.openid
      : undefined;
    let permit = this.$route.query.permit
      ? this.$route.query.permit
      : undefined;
    console.log("数字code", code);
    if (this.$route.query.code) {
      console.log("有code参数");
      if (code === 503) {
        console.log("走了吗");
        this.$router.push({
          path: "/about",
          query: { code: code, openid: openid, permit: permit },
        });
      } else if (code === 502) {
        console.log("授权失败");
      } else if (code === 200) {
        console.log("授权成功");
        window.location.href = `https://form-as.haiwojiamei.com/?permit=${permit}&route=add`;
      }
    } else {
      this.login();
    }
  },

  methods: {
    async login() {
      const str = this.redirect_url;
      const base = new Base64();
      const result = base.encode(str);
      console.log("加密", result);
      const { data } = await login({ redirect_url: result });
      this.oauth_url = data.data.oauth_url;
      console.log(data.data.oauth_url);
      const a = document.createElement("a");
      a.href = this.oauth_url;
      a.click();
    },
    sens() {
      this.isShow = true;
    },

    beforeClose() {
      this.isShow = false;
    },

    confirms() {
      console.log("sssss");
      this.$router.push({
        path: "/about",
      });
    },
    cancels() {
      console.log("qqqq");
    },
    btns() {
      // window.location.href=`http://localhost:8081/?action=oaushs`
    },
  },
};
</script>

<style scopde >
.nav {
  width: 100%;
  height: 15vh;
  border: 1px solid #999;
}
.main {
  margin-top: 10%;
}
.foot {
  width: 100%;
  margin-top: 10%;
}
.btn {
  width: 70% !important;
}
</style>