<template></template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    console.log(this.$route.query.token);
    localStorage.setItem("token", this.$route.query.token);
    window.location.href = `https://binding.haiwojiamei.com/applysubscription`;
  },
  methods: {},
};
</script>

<style>
</style>