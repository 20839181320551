<template>
  <div>
    <wx-open-subscribe
      template="b1FzVwl83sLILT74Se6JWwm5jVT3-D2rjLrc6RVdNNY"
      id="subscribe-btn"
    >
      <script type="text/wxtag-template" slot="style">
                    <style>
                      .subscribe-btn {
                          width: 300px;
                          height: 50px;
                          border-radius: 5px;
                          color: #fff;
                          background-color: #409EFF;
                          margin: 20px 0px;
                          font-size: 16px;

                      }
                    </style>
      </script>
      <script type="text/wxtag-template">
        <button class="subscribe-btn">
          消息订阅
        </button>
      </script>
    </wx-open-subscribe>
    <div class="title">
      <p>订阅智慧有方消息推送</p>
      <p>步骤一:点击上方消息订阅按钮</p>
      <p>步骤二:弹出如下图片,请勾选总是保持以上选择</p>
      <p>步骤三:点击确定</p>
    </div>
    <div class="nav">
      <img
        src="https://qiniu.haiwojiamei.com/upload/meijia/20230213/843e5e96ea6af37982b5cb1637ef0e03.png"
        alt=""
        class="pic"
      />
    </div>
  </div>
</template>
<script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import { store } from "../api/index.js";
import { token } from "../store/index.js";
import { Base64 } from "../utils/base.js";
import { config } from "../api/index.js";
import { Dialog } from "vant";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      redirect_url: "https://binding.haiwojiamei.com/applysubscription",
    };
  },
  created() {},
  mounted() {
    this.configs();
  },

  methods: {
    async configs() {
      const str = this.redirect_url;
      const base = new Base64();
      const results = base.encode(str);
      console.log(results);
      const { data } = await config({ url: results });
      console.log(data);
      if (data.code == 200) {
        wx.config({
          debug: data.data.debug, //是否开启调试功能，这里关闭！
          appId: data.data.appId, //appid
          timestamp: data.data.timestamp, //时间戳
          nonceStr: data.data.nonceStr, //生成签名的随机字符串
          signature: data.data.signature, //签名
          jsApiList: data.data.jsApiList,
          openTagList: data.data.openTagList,
        });

        wx.ready(function () {
          console.log("ssss");

          // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
        });

        wx.error(function () {
          console.log("zzzz");
          // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
        });

        var btn = document.getElementById("subscribe-btn");
        btn.addEventListener("success", function (e) {
          // alert(e.detail.subscribeDetails + "走的是成功success");
          // alert(e.detail.subscribeDetails);
          console.log(e);
          console.log(e.detail.subscribeDetails);
          var json = JSON.parse(e.detail.subscribeDetails);
          console.log(json);
          console.log("授权成功了");
          // var keys = Object.keys(json);
          // console.log(keys, "key");

          var value = Object.values(json);
          console.log(value, "value");
          var objs = {};
          let from = {
            template_id: [
              "b1FzVwl83sLILT74Se6JWwm5jVT3-D2rjLrc6RVdNNY",
            ],
            status: "",
          };
          for (const i in json) {
            json[i] = JSON.parse(json[i]);
            json[i] = json[i].status;
            console.log(json[i]);
          }
          objs = json;
          console.log(objs, "objs");
          const ts = Object.values(objs);
          console.log(ts);

          ts.forEach((item) => {
            console.log(item);
            if (item === "accept") {
              from.status = 1;
            } else {
              from.status = 0;
            }
          });

          console.log(from.status);
          // alert(from.status);
          if (from.status === 0) {
            setTimeout(() => {
              // alert('定时器走了没')
              store({ ...from }).then((res) => {
                // alert('进去请求')
                if (res.data.code === 200) {
                  Dialog.confirm({
                    showCancelButton: false,
                    title: "提示",
                    message: `订阅未成功`,
                  }).then(() => {
                    window.location.href = "https://binding.haiwojiamei.com/";
                  });
                } else {
                  Dialog.confirm({
                    showCancelButton: false,
                    title: "提示",
                    message: `订阅失败${data.message}`,
                  }).then(() => {
                    window.location.href = "https://binding.haiwojiamei.com/";
                  });
                }
              }).catch(err=>{
                alert(err)
              })
            }, 1000);
          } else if (from.status === 1) {
            store({ ...from }).then((res) => {
              if (res.data.code === 200) {
                Dialog.confirm({
                  showCancelButton: false,
                  title: "提示",
                  message: `订阅成功`,
                }).then(() => {
                  window.location.href = "https://binding.haiwojiamei.com/";
                });
              } else {
                Dialog.confirm({
                  showCancelButton: false,
                  title: "提示",
                  message: `订阅失败${data.message}`,
                }).then(() => {
                  window.location.href = "https://binding.haiwojiamei.com/";
                });
              }
            });
          }

          // for (const j in objs) {
          //   console.log(objs[j]);
          //   // alert(objs[j]);
          //   if (objs[j] === "accept") {
          //     console.log("成功");
          //     from.status = 1;
          //   } else {
          //     console.log("失败");
          //     from.status = 0;
          //     // alert("走没走失败");
          //   }
          // }
          // // alert(from.status);
          // if (from.status == 0) {
          //   store({ ...from }).then((res) => {
          //     if (res.data.code === 200) {
          //       alert("点击取消，状态已经存上去了");
          //     }
          //   });
          // } else {
          //   store({ ...from }).then((res) => {
          //     if (res.data.code === 200) {
          //       alert("点击确认，状态已经存上去了");
          //     }
          //   });
          // }

          // store({ ...from }).then((res) => {
          //   console.log(res, "111");
          //   console.log("请求触发了");
          //   console.log(from.status);
          //   alert(from.status + "请求不管成不成功看看是啥");
          //   if (res.data.code === 200) {
          //     console.log(from.status);
          //     alert(from.status + "看看是啥");
          //     //   console.log("请求200");

          //     Dialog.confirm({
          //       showCancelButton: false,
          //       title: "提示",
          //       message: `订阅成功`,
          //     }).then(() => {
          //       // on confirm
          //       window.location.href = "https://binding.haiwojiamei.com/";
          //     });
          //   } else {
          //     console.log("请求400");
          //     console.log(data.message);
          //     Dialog.confirm({
          //       showCancelButton: false,
          //       title: "提示",
          //       message: `${data.message}`,
          //     }).then(() => {
          //       // on confirm
          //       window.location.href = "https://binding.haiwojiamei.com/";
          //     });
          //   }
          // });
        });
        btn.addEventListener("error", function (e) {
          // alert(e.detail.subscribeDetails + "走的是error");
          console.log(e.detail.subscribeDetails + "走的是error");
          alert(e.detail.subscribeDetails + "走的是error");

          // alert(e.detail.subscribeDetails)
        });
      }
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  height: 50%;
}
.pic {
  width: 100%;
  height: 100%;
}
.title {
  width: 100%;
  text-align: left;
  padding: 5px 8px;
  margin-bottom: 10px;
}
</style>