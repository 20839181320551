import request from "@/utils/request";


export const login = params => {
    return request({
        url: `/oauth`,
        method: 'get',
        params
    })
}

export const userinfo = params => {
    return request({
        url: `/user/info`,
        method: 'get',
        params
    })
}

export const userbind = data => {
    return request({
        url: `/user/bind`,
        method: 'post',
        data
    })
}

// 订阅消息授权 
export const message = params => {
    return request({
        url: `/message/subscribe`,
        method: 'get',
        params
    })
}

// config
export const config = params => {
    return request({
        url: `/message/subscribe/jssdkconfig`,
        method: 'get',
        params
    })
}

// 授权状态保存 
export const store = data => {
    return request({
        url: `/message/subscribe/store`,
        method: 'post',
        data,
        headers: {
            token: localStorage.getItem("token"),
            'Content-Type': 'multipart/form-data'

        }
    })
}