<template>
  <div class="box">
    <van-nav-bar title="绑定成功" left-arrow />
    <div class="imgs">
      <img
        src="https://qiniu.haiwojiamei.com/upload/meijia/20230116/c89e6ce9d38ca46b7a8ef716a8279e10.png"
        alt=""
        class="pic"
      />
    </div>

    <div class="foot">
      <div>已绑定</div>

      <div class="title">您以绑定手机号</div>

      <div class="title">欢迎使用智慧有方功能</div>
    </div>

    <van-button round type="info" class="btn" @click="sens"
      >关闭页面</van-button
    >
  </div>
</template>

<script>
export default {
  created() {
    console.log(this.$route.query, "sssss");
  },
  methods: {
    sens() {
      this.$router.push({
        path:'/'
      })
    },
  },
};
</script>

<style scoped>
.imgs {
  width: 100%;
  margin-top: 14%;
}
.pic {
  width: 15%;
}
.foot {
  width: 100%;
  text-align: center;
}
.title {
  color: #999;
}
.btn {
  width: 70%;
  margin-top: 20%;
}
</style>