import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import bing from '../views/bing.vue'
import applybing from '../views/applybing.vue'
import applysubscription from '../views/applysubscription.vue'
import tokens from '../views/tokens.vue'
import quick from '../views/quick.vue'
const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/bing',
    name: 'bing',
    component: bing,
  },
  {
    path: '/applybing',
    name: 'applybing',
    component: applybing,
  },
  {
    path: '/applysubscription',
    name: 'applysubscription',
    component: applysubscription
  },
  {
    path: '/tokens',
    name: 'tokens',
    component: tokens
  },
  {
    path: '/quick',
    name: 'quick',
    component: quick
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
